import React from 'react';
import parse from 'html-react-parser';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Label,
} from "@progress/kendo-react-labels";
import DateTimePicker, { DateTimePickerModes } from '../DateTimePicker';
import { Error } from '../labels/Error';

function DateTimePickerFieldWrapper({
    title = "",
    value = null,
    defaultValue = null,
    disabled = false,
    canDelete = false,
    onChange = null,
    warnings = [],
    mode = DateTimePickerModes.DateTime,
    boldTitle = false,
    showTimeTab = false
}) {

    return (
        <FieldWrapper>
            <Label className="k-form-label" style={{
                fontWeight: boldTitle ? "500" : "normal"
            }}>
                {parse(title)}:
            </Label>
            <div className="k-form-field-wrap">
                <DateTimePicker
                    title={title}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    canDelete={canDelete}
                    onChange={onChange}
                    mode={mode}
                    showTimeTab={showTimeTab}
                />
            </div>
            {
                warnings.map((warning, index) =>
                    <Error key={index}>
                        {parse(warning)}
                    </Error>
                )
            }
        </FieldWrapper>
    );
}

export default DateTimePickerFieldWrapper; 
